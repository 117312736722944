<template>
  <v-container
    fluid
    class="checkout cart"
    :class="{ 'w-90': $vuetify.breakpoint.lgAndUp }"
  >
    <PaymentStepper :step="1" class="mx-0 mx-sm-auto mt-3 mt-sm-6" />
    <CategoryTitle
      :category="category"
      :showOnMobile="true"
      :centered="$vuetify.breakpoint.xs"
    />
    <ResponseMessage :response="response" />

    <v-row>
      <v-col cols="12" v-if="!cart.user.profile.confirmed">
        <CheckoutConfirmEmail
          :key="cart.refreshTime"
          @sendEmail="sendActivationMail"
        />
      </v-col>
      <v-col cols="12" v-if="profileLevel < minProfileLevel">
        <CheckoutCompleteProfile />
      </v-col>
      <v-col cols="12" sm="4">
        <CartInfoAddressCard
          :shippingAddress="cart.shippingAddress"
          :editable="editableAddress"
          @edit="openAddressSelector"
          :isCheckout="true"
          class="w-100"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <CartInfoTimeslotCard
          :shippingAddress="cart.shippingAddress"
          :timeslot="cart.timeslot"
          :editable="editableTimeslot"
          @edit="openTimeslotSelector"
          :isCheckout="true"
          class="w-100 cart-info-timeslot-card"
          :value="timeslot"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <div class="gross-total">
          <span class="primary--text">Totale:</span>
          <span>
            {{ $n(cart.grossTotal, "currency") }}
          </span>
        </div>
        <div class="gross-total-btn">
          <v-btn
            class="rounded-xl default--text"
            color="primary"
            depressed
            :disabled="cart.totalItems == 0"
            :loading="loading"
            :to="{ name: 'Checkout' }"
          >
            {{ $t("checkout.goToPaymentBtn") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <CartItemList
      class="items-list"
      :isCheckout="true"
      :full="$vuetify.breakpoint.xs"
    />
    <div
      class="checkout-actions d-flex justify-space-between align-center flex-column flex-md-row"
    >
      <div class="d-flex flex-column flex-md-row justify-start cart-btn-cnt">
        <v-btn
          color="secondary"
          class="mr-0 mr-md-2 mb-2 mb-md-0 rounded-xl"
          x-large
          depressed
          outlined
          @click="addAllToFavorites"
        >
          <span>{{ $t("checkout.addToListBtn") }}</span>
        </v-btn>
        <v-btn
          color="secondary"
          class="rounded-xl"
          x-large
          outlined
          depressed
          @click="emptyCartConfirm"
        >
          <span>{{ $t("checkout.emptyCartBtn") }}</span>
        </v-btn>
      </div>
      <div class="d-flex flex-column justify-end gross-total-cnt">
        <div class="gross-total">
          <span class="primary--text">Totale:</span>
          <span>
            {{ $n(cart.grossTotal, "currency") }}
          </span>
        </div>
        <div class="gross-total-btn">
          <v-btn
            class="rounded-xl default--text"
            color="primary"
            x-large
            depressed
            :disabled="cart.totalItems == 0"
            :loading="loading"
            :to="{ name: 'Checkout' }"
          >
            {{ $t("checkout.goToPaymentBtn") }}
          </v-btn>
        </div>
      </div>
    </div>
    <category-block
      :target="category"
      :container="false"
      position="position2"
      class="category-block category-block-2"
    />

    <v-row>
      <v-col
        cols="12"
        md="9"
        class="pt-0"
        :order="$vuetify.breakpoint.smAndDown ? 'last' : 0"
      >
        <v-row justify="space-between" class="mb-3 px-3 px-sm-0">
          <v-col cols="12" order="4" class="px-0 px-md-3">
            <gift-list
              v-if="giftCertificates.length > 0"
              :mode="'checkout'"
              :title="$t('checkout.giftCodeSubtitle')"
              :giftList="cart.giftCertificates"
              @reload="loadGiftCertificates"
            />
            <v-alert
              v-if="error"
              type="error"
              border="left"
              transition="v-fab-transition"
            >
              {{ error }}
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.cart {
  .checkout-actions {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
    }
  }
  .gross-total-btn {
    text-align: right;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      text-align: center;
    }
  }
  .gross-total-cnt,
  .cart-btn-cnt {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
      justify-content: center;
      a {
        width: 100%;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .gross-total {
      text-align: center !important;
    }
  }
}
.checkout {
  .gross-total {
    text-align: right;
    font-size: 28px;
    font-weight: bold;
  }
  .gross-total-btn {
    text-align: right;
  }
  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .privacy-check {
    a {
      color: var(--v-default-base) !important;
      font-weight: bold;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .checkout-title {
        h1 {
          font-size: 24px !important;
        }
      }
    }
  }
  .product-price {
    margin: 0 4px;
  }
  .qty-wrap {
    max-width: 160px;
    min-width: 150px;
    height: 50px;
  }
  .checkout-item-list {
    background: transparent;
    .v-subheader {
      background: transparent;
    }
  }
}
</style>
<script>
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import CheckoutConfirmEmail from "@/components/cart/CheckoutConfirmEmail.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
// import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import FastPayment from "@/components/cart/FastPayment.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import AnalyticsService from "~/service/analyticsService";
import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";
import PersonalCouponService from "@/service/personalCouponService";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { forEachCartItem } from "~/service/ebsn.js";
import { mapActions, mapState, mapGetters } from "vuex";

import get from "lodash/get";
import GiftList from "@/components/gift/GiftList.vue";

export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    PaymentStepper,
    CategoryTitle,
    CheckoutConfirmEmail,
    CheckoutCompleteProfile,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartItemList,
    ResponseMessage,
    GiftList
  },
  data() {
    return {
      error: null,
      loading: false,
      response: {},
      minProfileLevel: global.config.minProfileLevel,
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      iosMobile: false,
      showPersonalCoupon: false,
      dialogInstance: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel"
    }),
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    }
  },

  methods: {
    get: get,
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart"
    }),
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list"
      );
      var manualGiftCertificates = await RegistrationService.listGiftCertificate(
        "manual"
      );

      this.giftCertificates = [
        ...giftCertificates,
        ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return !!giftCertificate.giftCode || hasUserGift.length > 0;
      });
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      let _this = this;
      const data = await RegistrationService.getUserDetail();
      // merge with used certificates
      _this.giftCertificates = data.giftCertificatesReceived || [];
    },
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Payment"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    }
  },
  async mounted() {
    // if (!this.cart.user.profile.confirmed) {
    //   this.sendActivationMail();
    // }
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    AnalyticsService.beginCheckout(this.cart);
    this.checkOsx();
    this.loadGiftCertificates();
    let personalCoupon = await PersonalCouponService.checkCoupon();
    if (
      personalCoupon &&
      personalCoupon.gift_certificates &&
      personalCoupon.gift_certificates.length > 0
    ) {
      this.showPersonalCoupon = true;
      await this.getCart();
    }
  }
};
</script>
